<template>
  <BaseCardDialog
    v-model="dialog"
    title="Ultra detailed data"
    :full-width="false"
    :width="'1200px'"
    :no-padding="true"
  >
    <template v-slot:content>
        <v-row no-gutters class="body-ultra-detailed">
          <v-col>
            <ScrollableTable
              :headers="ultraDetailedHeader"
              :items="ultraDetailedHeader!== null
                          ? getItems(alert, ultraDetailedItem)
                          : alert"
              :class="'elevation-2 ' + 'temp_' + ultraDetailedData"

            >
              <template v-slot:line="lineProp">
                <component
                  v-if="!isIterable(ultraDetailedData)"
                  v-bind:is="ultraDetailedData"
                  :propData="lineProp.item"
                  :alert="alert"
                  :ckey="lineProp.ckey"
                  :item="lineProp.item"
                  :index="lineProp.index"
                >
                </component>
                <component
                  v-else
                  v-for="(item, key ,index) in treatementsToApply(lineProp.item)"
                  v-bind:key="key + '_' + index + Math.random()"
                  v-bind:is="ultraDetailedData"
                  :propData="lineProp.item"
                  :alert="alert"
                  :ckey="key"
                  :item="item"
                  :index="index"
                >
                </component>
              </template>
            </ScrollableTable>
          </v-col>
        </v-row>
    </template>
  </BaseCardDialog>
</template>

<script>
import BaseCardDialog from '../../../../Common/BaseCardDialog'
import ScrollableTable from '../../../../Common/ScrollableTable'

export default {
  name: 'UltraDetailedDialog',
  props: {
    alert: {
      type: Object,
      default: function () {
        return {}
      }
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BaseCardDialog,
    ScrollableTable,
    UnderdeliveryDetailed: () => import('./UnderdeliveryDetailed.vue'),
    UnderdeliveryEndOfBillingDetailed: () => import('./UnderdeliveryEndOfBillingDetailed.vue'),
    IntradayPacingOffTracksDetailed: () => import('./IntradayPacingOffTracksDetailed.vue'),
    WrongSyntaxDetailed: () => import('./WrongSyntaxDetailed.vue'),
    UnsupportedBusinessModelDetailed: () => import('./UnsupportedBusinessModelDetailed'),
    OverdeliveryDetailed: () => import('./OverdeliveryDetailed'),
    PerfFirstDeliveryDropDetailed: () => import('./PerfFirstDeliveryDropDetailed'),
    PerfFirstNoDeliveryDetailed: () => import('./PerfFirstNoDeliveryDetailed')
  },
  data: function () {
    return {
      dialog: false,
      treatementsPerErrorType: {
        // UnderdeliveryDetailed: 'sortDateDetailed'
      },
      infoErrorType: {}
    }
  },
  created: function () {

  },
  mounted: function () {
    this.updateinfoErrorType()
  },
  methods: {
    getItems (alert, ultraDetailedItem) {
      let res = this.$safeGet(alert, ultraDetailedItem)
      return res || [alert]
    },
    isIterable: function (errorName) {
      let iterable = [
        // '3.1.2'
      ]
      return iterable.indexOf(errorName) !== -1
    },
    treatementsToApply: function (data) {
      let ultraDetailedName = this.infoErrorType.ultraDetailedData

      if (Object.keys(this.treatementsPerErrorType).indexOf(ultraDetailedName) === -1) {
        return data
      }

      let func = this.treatementsPerErrorType[ultraDetailedName]
      return this[func](data)
    },
    sortDateDetailed: function (data) {
      let dateKey = Object.keys(data)

      dateKey.sort(function (a, b) {
        return new Date(b) - new Date(a)
      })

      let sortedData = {}

      for (let i in dateKey) {
        sortedData[dateKey[i]] = data[dateKey[i]]
      }
      return sortedData
    },
    updateinfoErrorType () {
      this.infoErrorType = this.$getInfoError(this.alert.error_type)
    }
  },
  computed: {
    ultraDetailedHeader: function () {
      return this.infoErrorType.ultraDetailedHeader
    },
    ultraDetailedItem: function () {
      return this.infoErrorType.ultraDetailedItem
    },
    ultraDetailedData: function () {
      return this.infoErrorType.ultraDetailedData
    }
  },
  watch: {
    value (val) {
      if (val !== this.dialog) {
        this.dialog = val
      }
    },
    dialog (val) {
      this.$emit('input', val)
    },
    alert (val) {
      this.updateinfoErrorType()
    }

  }
}
</script>

<style>
  .body-ultra-detailed .fixed-headers {
    margin-left: auto;
    margin-right: auto;
  }

</style>
