<template>
  <table class="fixed-headers scrollable-table">
    <thead>
      <tr>
        <slot name="header">
          <th v-for="(item, index) in headers" v-bind:key="index">{{item.label}}</th>
        </slot>
      </tr>
    </thead>
    <tbody>
      <template v-for="(item, key ,index) in items">
        <slot v-bind:item="item" v-bind:ckey="key" v-bind:index="index" name="line">
          <tr v-bind:key="index">
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </slot>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'ScrollableTable',
  props: {
    items: {
      type: [Array, Object],
      default: function () {
        return []
      }
    },
    headers: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {

  },
  data: function () {
    return {

    }
  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>

.scrollable-table {
  font-size: 9px;
  text-align: center;
}

.scrollable-table td {
  min-width: 10em;
}
</style>
